import { Component } from '@angular/core';

interface SubscriptionsInterface {
  count: string | number;
  name: string;
  text: string;
  cost: number;
}

@Component({
  selector: 'eng-all-subscriptions',
  templateUrl: './all-subscriptions.component.html',
  styleUrls: ['./all-subscriptions.component.scss'],
})
export class AllSubscriptionsComponent {
  get subscriptions(): SubscriptionsInterface[] {
    return [
      {
        count: 1,
        name: 'Base',
        text: `Any fitness trainer will tell you how critical it is for you to develop and maintain a strong core. The core muscle groups in your body `,
        cost: 22,
      },
      {
        count: 6,
        name: 'Extra',
        text: `Any fitness trainer will tell you how critical it is for you to develop and maintain a strong core. The core muscle groups in your body `,
        cost: 22,
      },
      {
        count: 12,
        name: 'Platinum',
        text: `Any fitness trainer will tell you how critical it is for you to develop and maintain a strong core. The core muscle groups in your body `,
        cost: 22,
      },
      {
        count: 'All',
        name: 'All access',
        text: `Any fitness trainer will tell you how critical it is for you to develop and maintain a strong core. The core muscle groups in your body `,
        cost: 22,
      },
    ];
  }
  constructor() {}
}
