<h2 class="accent title bold-5 accent">
  {{ 'payment.payment' | translate }}
</h2>
<div class="subscriptions">
  <div class="subscription" *ngFor="let subscription of subscriptions">
    <div class="header">
      <div class="count-games">
        <div class="h9 berry bold">{{ subscription.count }} {{ 'payment.games' | translate }}</div>
      </div>
      <div class="h6 name bold">{{ subscription.name }}</div>
    </div>
    <div class="h9 dark bold-3 description line-height-22">{{ subscription.text }}</div>
    <div class="bottom">
      <button mat-flat-button class="h6 snow" color="accent">
        {{ 'payment.buy' | translate }}
      </button>
      <h4 class="accent bold cost">
        {{ subscription.cost }}
        <eng-dynamic-icon name="payment" color="accent" size="m" class="inline"></eng-dynamic-icon>
      </h4>
    </div>
  </div>
</div>
